<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="providersStore.provider.d_licenseNumber + ' - ' +
      providersStore.provider.d_nameLicense" />

      <!--Function Bar-->
      <provider-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>

          <!--Provider Applications-->
          <v-col cols="12" sm="12">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-sliders-h-square</v-icon>
                <v-toolbar-title class="pl-2">Preferences</v-toolbar-title>

                <v-spacer />
                <provider-entry-preferences />
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5" height="100%">
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" sm="12" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <text-display :displayLabel="'Mail Monthly Attendance Sheets To'" :displayString="providersStore.provider.d_mailingsAttendance" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <text-display :displayLabel="'Mail Monthly Payment Details To'" :displayString="providersStore.provider.d_mailingsPayment" />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <text-display :displayLabel="'Special Requests'" :displayString="providersStore.provider.d_specialRequests" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <text-display
                          :displayLabel="'Opt-In to Texting Program'"
                          :displayString="$_yesNo(providersStore.provider.f_textingOptIn)" />
                      </v-col>
                    </v-row>

                    <v-row dense v-if="providersStore.provider.f_textingOptIn">
                      <v-col cols="12" sm="12" md="6">
                        <text-display :displayLabel="'Cellphone Owner'" :displayString="providersStore.provider.d_textingName" />
                      </v-col>
                    </v-row>

                    <v-row dense v-if="providersStore.provider.f_textingOptIn">
                      <v-col cols="12" sm="12" md="6">
                        <text-display :displayLabel="'Cellphone'" :displayString="providersStore.provider.d_textingPhone" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import ProviderFunctionBar from '@/components/ProviderFunctionBar';
import TextDisplay from '@/components/TextDisplay';
import ProviderEntryPreferences from '@/components/ProviderEntryPreferences';

export default {
  name: 'ProviderPreferences',
  mixins: [mixin],
  computed: {
    ...mapState({
      providersStore: (state) => state.providersStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
	  TitleBar,
    ProviderFunctionBar,
    TextDisplay,
    ProviderEntryPreferences,
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      provider: {
        id: 1,
        uuid: 1234567,
        name: 'Facility Number 1',
        license: '123456',
        type: 'Center',
        rating: '5 Star',
        address: '1010 Main Street',
        phone: '999-999-9999',
        fax: '888-888-8888',
        website: 'www.test.com',
        county: 'Durham',
        status: 'Active',
        ratingDate: '8/24/2017',
      },
      notes: [
        {
          id: 1,
          type: 'A new note',
          date: '4/21/2019',
        },
      ],
    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('providersStore/read', this.$route.params.providerUUID);
      this.$store.dispatch('progressStore/set', false);
	  },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
